.toggleColumn
  width: 100%
  display: flex
  flex-direction: column
  align-items: center

.toggleContainer
  display: flex
  flex-direction: row
  align-items: center
  gap: 12px
  padding: 0
  white-space: nowrap

.toggleLabel
  font-size: 16px
  font-weight: 600
  color: #1C1C1C
  margin-right: 8px