.wrapper
  width: 100%
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden
  padding: 20px

.giftsWrapper
  display: flex
  flex-direction: column
  width: 100%
  flex-grow: 1
  overflow-y: auto
  overflow-x: hidden
  .noItems
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    span
      font-size: 2rem

.categorySection
  padding-top: 8px
  margin-bottom: 16px

.items
  display: flex
  flex-direction: column
  gap: 12px

.itemContainer
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  border-radius: 8px
  padding: 8px

.itemDetails
  display: flex
  flex-direction: row
  align-items: center
  gap: 16px
  flex: 1

.itemImage
  width: 72px
  height: 72px
  border-radius: 6px
  border: 1.286px solid #F2F2F7
  display: flex
  justify-content: center
  align-items: center
  overflow: hidden
  background-color: #F9F9F9


.voucherImage
  width: 100%
  height: auto
  max-height: 100%
  object-fit: contain

.itemInfo
  display: flex
  flex-direction: column
  gap: 4px

.itemName
  color: #1C1C1C
  font-size: 15px
  font-weight: 400

.itemPrice
  color: #65656C
  font-size: 14px
  font-weight: 400

.addItemBtn
  width: 48px
  height: 48px
  border-radius: 50%
  background-color: #FFF5F0
  border: none
  outline: none
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  
  &:focus
    outline: none
    box-shadow: none