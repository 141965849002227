.wrapper
  width: 100%
  height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden 

.headerSection
  width: 100%
  z-index: 10
  position: sticky
  top: 0

.box
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 20px 16px 10px 16px
  width: 100%
  gap: 16px
  .search
    width: 100%
    display: flex
    padding: 16px
    height: 56px
    border: 1px solid #D1D1D6
    border-radius: 8px
    align-items: center
    gap: 12px
    background-color: transparent
    .logo
      height: 24px
      width: 24px
    .input
      outline: none
      border: none
      color: #8E8E93
      font-size: 16px
      font-weight: 400
      flex: 1
      background-color: transparent
    .input::placeholder
      color: #8E8E93
      font-size: 16px
      font-weight: 400

.tabsContainer
  width: 100%
  padding: 5px 0 16px 0
  position: relative
  
.tabs
  display: flex
  overflow-x: auto
  padding: 0 16px
  gap: 12px
  scroll-behavior: smooth
  -webkit-overflow-scrolling: touch 
  scrollbar-width: none             
  &::-webkit-scrollbar
    display: none      
  
.tab
  white-space: nowrap
  padding: 8px 16px
  border-radius: 20px
  background-color: transparent
  border: 1px solid #D1D1D6
  font-size: 14px
  font-weight: 500
  color: #8E8E93
  cursor: pointer
  transition: all 0.2s ease
  outline: none
  flex-shrink: 0
  &:hover
    border-color: #D1D1D6
  
  &:focus
    outline: none  
  
  &:active
    outline: none
    background-color: rgba(242, 242, 247, 0.4)  
  
.selectedTab
  background-color: #FFF4EA
  color: #FFA351
  font-weight: 600
  border-color: #FFA351 
  &:hover
    border-color: #FFA351 !important  
    background-color: #FFF4EA !important  
  
  &:focus
    border-color: #FFA351 !important
    background-color: #FFF4EA !important
  
  &:active
    border-color: #FFA351 !important
    background-color: #FFF4EA !important

.categorySection
  padding-top: 8px  

.giftsWrapper
  display: flex
  flex-direction: column
  padding: 8px 26px
  width: 100%
  flex-grow: 1  
  overflow-y: auto 
  overflow-x: hidden  
  .noItems
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center
    span
      font-size: 2rem