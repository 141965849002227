.wrapper
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    .categoryName
        color: #65656C
        font-size: 18px
        font-weight: 700
        margin-bottom: 8px
    .items
        display: flex
        flex-direction: column
        gap: 12px
        .itemContainer
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            .addItemBtn
                width: 48px
                height: 48px
                border-radius: 50%
                background-color: #FFF5F0
                border: none
                outline: none
                cursor: pointer
                display: flex
                justify-content: center
                align-items: center
            .itemDetails
                display: flex
                flex-direction: row
                align-items: center
                gap: 16px
                .itemInfo
                    display: flex
                    flex-direction: column
                    gap: 4px
                    .itemName
                        color: #1C1C1C
                        font-size: 15px
                        font-weight: 400
                    .itemPrice
                        color: #65656C
                        font-size: 14px
                        font-weight: 400
                .itemImage
                    width: 72px
                    min-width: 72px
                    height: 72px
                    border-radius: 6px
                    border: 1.286px solid #F2F2F7
                    display: flex
                    justify-content: center
                    align-items: center
                    overflow: hidden
                    background-color: #f09440
                    
                    img
                        width: 100%
                        height: 100%
                        object-fit: cover
                    
                    .iconWithoutImage
                        display: flex
                        width: 38px
                        height: 38px
                        justify-content: center
                        opacity: 0.22