.wrapper
  display: flex
  flex-direction: column
  padding: 4.4rem 2.6rem 2.6rem
  width: max-content
  .uninstallWrapper
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    .uninstallTitle
      font-size: 1.6rem
      font-weight: 900
      color: #2d2d2d
    .uninstallBtnWrapper
      display: flex
      flex-direction: row
      align-items: center

      .uninstallBtn
        outline: none
        font-size: 1.6rem
        color: #fff
        border-radius: 4px
        background-color: #EE5253
        padding: 16px 24px
        font-weight: 700

  .blockedUsers
    cursor: pointer
    border-left: 4px solid #481376
    display: flex
    align-items: center
    justify-content: space-between
    padding: 1rem 2rem
    margin: 2.8rem 0 0
    border-radius: 5px
    box-shadow: 1px 2px 10px 0 rgba(2, 10, 39, 0.1)
    background: #ffffff
    *
      margin: 0
    img.arrow
      transform: rotate(180deg)
      width: 3rem
      height: 3rem
    .textWrapper
      .header
        font-size: 1.8rem
        font-weight: 500
        color: #2d2d2d
        margin: 0 0 .15rem
      .subHeader
        margin: .15rem 0 0
        font-size: 1.2rem
        color: #9b9b9b
  .title
    margin: 0
    font-size: 3.6rem
    font-weight: 900
    line-height: 1.33
    color: #222222
  .settingsWrapper
    margin-top: 7.4rem
    .settingWrapper
      display: flex
      cursor: pointer
      align-items: center
      justify-content: space-between
      margin-bottom: 1.1rem
      border-bottom: 1px solid #ebebeb
      padding-bottom: 0.5rem
      .settingText
        .settingTitle
          margin-bottom: 0.4rem
          font-size: 1.8rem
          font-weight: 500
          color: #2d2d2d
        .settingSubTitle
          font-size: 1.2rem
          color: #9b9b9b
      .settingSwitch
        margin-left: 16.4rem
        direction: ltr !important
  .expirationPeriodWrapper
    margin-top: 2.6rem
    padding: 1rem 0
    display: flex
    align-items: center
    .expirationTitle
      font-size: 1.6rem
      font-weight: 900
      color: #2d2d2d
    .expirationSelect
      margin: 0 1rem
      .dropDownToggle
        height: 4.6rem
        padding: 0 1.6rem !important
        box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1)
        border-radius: 5px
        border: solid 1px #e3e3e3

.wrapper.rtl
  .blockedUsers
    border-right: 4px solid #481376
    border-left: 0
    img.arrow
      transform: unset
  .settingsWrapper
    .settingWrapper
      .settingSwitch
        margin-left: 0
        margin-right: 16.4rem

.settingSwitch.rtl
  transform: scale(-1) !important
