.wrapper
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    align-items: flex-start
    padding-top: 40px

    .contentWrapper
        width: 100%
        display: flex
        flex-direction: row
        gap: 40px

    .toggleColumn
        width: 15%
        display: flex
        flex-direction: column
        align-items: center
        padding: 25px 0 0 25px

    .list
        width: calc(85% - 470px)
        height: 100%
        padding-bottom: 24px
        display: flex
        flex-direction: column
        gap: 20px

    .form
        width: 440px
        height: 100vh
        overflow: auto
        border: 1px solid #E5E5EA
        z-index: 1
        position: absolute
        right: 0
        top: 100px
        scrollbar-width: none 
        -webkit-overflow-scrolling: touch 
        &::-webkit-scrollbar  
            display: none
        .title
            font-size: 16px
            font-weight: 600
            padding: 8px 0

.loadingIndicator
    display: flex
    justify-content: center
    align-items: center
    height: 100%
    width: 100%

.emptyListMessage
    padding: 20px
    font-size: 16px
    color: #888
    text-align: center
    margin-top: 50px
