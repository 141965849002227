@keyframes successBorderPulse
  0%
    border-color: #4CD964 
  70%
    border-color: #4CD964 
  100%
    border-color: #D1D1D6 

.cardWrapper
  width: 100%
  height: 160px
  display: flex
  flex-direction: column
  gap: 12px
  align-items: center
  padding: 16px 16px
  border-radius: 8px
  border: 1px solid #D1D1D6  
  transition: border-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease
  position: relative
  box-sizing: border-box
  cursor: pointer  
  
  &:hover
    transform: translateY(-2px)
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    
  &.cardWrapper.activeCard
    border-color: #FFA351 
    border-width: 2px
    border-style: solid
    box-shadow: 0 0 0 2px rgba(255, 144, 0, 0.2)
  
  &.cardWrapper.filledSingleCard
    border-color: #4CD964
    border-width: 2px
    border-style: solid
    box-shadow: none
  
  &.cardWrapper.filledItemCard
    border-color: #D1D1D6
    border-width: 1px
    border-style: solid
    box-shadow: none
  
  &.cardWrapper.filledInCompleteRow
    border-color: #D1D1D6
    border-width: 1px
    border-style: solid
    box-shadow: none
  
  &.cardWrapper.filledInCompleteRowActive
    border-color: #FFA351 
    border-width: 2px
    border-style: solid
    box-shadow: 0 0 0 2px rgba(255, 144, 0, 0.2)
    
  &.cardWrapper.cardSuccess
    border-color: #4CD964
    border-width: 2px
    border-style: solid
    box-shadow: none
    animation: successBorderPulse 2s ease-out forwards

.emptyCard
  display: flex
  flex-direction: column
  justify-content: center 

.filledCard
  border-width: 1px
  border-color: #D1D1D6
  border-style: solid

.imgWrapper
  width: 72px
  height: 72px !important  
  min-height: 72px  
  max-height: 72px  
  display: flex
  align-items: center
  justify-content: center
  border: 2px dashed #E3E3E3
  border-radius: 50%
  overflow: hidden
  box-sizing: border-box 

  .activeCard &, .filledSingleCard &, .filledItemCard &, .filledInCompleteRow &, .filledInCompleteRowActive &, .cardSuccess &
    border-style: solid
    border-color: #E3E3E3

.img
  width: 24px
  height: 24px

.itemImage
  width: 100%
  height: 100%
  object-fit: cover
  border-radius: 50%

.iconWithoutImage
  width: 38px
  height: 38px
  opacity: 0.22

.dataWrapper
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  gap: 4px

.itemName
  font-size: 16px
  font-weight: 400
  color: #1C1C1C
  margin: 0
  text-align: center
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  max-width: 100%

.itemPrice
  font-size: 14px
  font-weight: 400
  color: #65656C
  margin: 0
  text-align: center