.container
  width: 100%
  max-width: 500px
  margin: 0 auto
  display: flex
  flex-direction: column
  gap: 24px

.headerRow
  display: grid
  grid-template-columns: 189.5px 100px 189.5px
  width: 100%
  margin-bottom: 16px

.header
  background-color: #F2F2F7
  border-radius: 500px
  padding: 8px 16px
  width: 189.5px
  height: 38px
  display: flex
  align-items: center
  justify-content: center
  &:first-child
    grid-column: 1
  &:last-child
    grid-column: 3

.title
  font-size: 16px
  font-weight: 600
  color: #1C1C1C
  text-align: center
  margin: 0

.rows
  display: flex
  flex-direction: column
  gap: 40px
  width: 100%

.row
  display: grid
  grid-template-columns: 189.5px 100px 189.5px
  align-items: center
  width: 100%
  position: relative
  border-radius: 8px
  padding: 4px
  transition: all 0.3s ease

.item
  width: 100%
  display: flex
  justify-content: center

.connectionContainer
  position: relative
  height: 2px
  width: 100%

.horizontalLine
  width: 100%
  height: 2px
  background-color: #D1D1D6
  position: relative
  transition: background 0.3s ease
  &.completeReferralGift
    background: linear-gradient(to right, #FFA351 0%, #FFA351 50%, #FFA351 0%, #FFA351 50%)
  &.completeWithSenderActive
    background: linear-gradient(to right, #FFA351 0%, #FFA351 50%, #D1D1D6 50%, #D1D1D6 100%)

  &.completeWithRecipientActive
    background: linear-gradient(to right, #D1D1D6 0%, #D1D1D6 50%, #FFA351 50%, #FFA351 100%)

  &.senderActiveFilledOnly
    background: linear-gradient(to right, #FFA351 0%, #FFA351 50%, #D1D1D6 50%, #D1D1D6 100%)

  &.senderFilledRecipientActive
    background: linear-gradient(to right, #4CD964 0%, #4CD964 50%, #FFA351 50%, #FFA351 100%)

  &.recipientActiveFilledOnly
    background: linear-gradient(to right, #D1D1D6 0%, #D1D1D6 50%, #FFA351 50%, #FFA351 100%)

  &.senderActiveRecipientFilled
    background: linear-gradient(to right, #FFA351 0%, #FFA351 50%, #4CD964 50%, #4CD964 100%)

  &.recipientActiveOnly
    background: linear-gradient(to right, #FFA351 0%, #FFA351 50%, #D1D1D6 50%, #D1D1D6 100%)

  &.senderActiveOnly
    background: linear-gradient(to right, #D1D1D6 0%, #D1D1D6 50%, #FFA351 50%, #FFA351 100%)

  &.senderFilledOnly
    background: linear-gradient(to right, #4CD964 0%, #4CD964 50%, #D1D1D6 50%, #D1D1D6 100%)

  &.recipientFilledOnly
    background: linear-gradient(to right, #D1D1D6 0%, #D1D1D6 50%, #4CD964 50%, #4CD964 100%)

  &.bothActive
    background: #FFA351

.deleteRowButton
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  width: 32px
  height: 32px
  border-radius: 50%
  background-color: #F2F2F7
  border: none
  display: flex
  align-items: center
  justify-content: center
  cursor: pointer
  padding: 0
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
  z-index: 1
  outline: none
  transition: background-color 0.2s ease

  &:hover
    background-color: #FFA351
    svg
      stroke: white

  &:focus, &:active
    outline: none
    border: none

.addRowButtonContainer
  margin-top: 24px
  width: 100%
  display: grid
  grid-template-columns: 189.5px 100px 189.5px

.addRowButton
  grid-column: 1 / span 3
  display: flex
  align-items: center
  justify-content: center
  gap: 8px
  width: 100%
  padding: 12px 16px
  background-color: #FAFAFA
  border: 1px solid #AEAEB2
  border-radius: 8px
  color: #1C1C1C
  font-size: 16px
  font-weight: 500
  cursor: pointer
  transition: all 0.2s ease
  &:hover
    background-color: #E5E5EA
    border-color: #C7C7CC
  &:active
    background-color: #D1D1D6
  &:focus
    outline: none

.addRowButtonIcon
  font-size: 16px
  color: #1C1C1C

@keyframes successLineFlash
  0%
    background-color: #4CD964
  70%
    background-color: #4CD964
  100%
    background-color: #D1D1D6

.connectionSuccess
  background: #4CD964 !important
  animation: successLineFlash 2s ease-out forwards
