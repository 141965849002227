@import "~antd/dist/antd.less";

* {
  font-family: "Nunito Sans", sans-serif;
}

// Multi select components overrides
.ant-picker,
.ant-select {
  border-radius: 50px;
  background-color: #f2f2f7;
  border: 0px;
  outline: none !important;
}

.square-input .ant-dropdown-trigger {
  border-radius: 4px !important;
}

.square-input .ant-select {
  border-radius: 4px !important;
}

.input-title {
  margin-bottom: 0.3rem;
  font-size: 16px;
}

.ant-dropdown-trigger {
  border-radius: 50px !important;
  background-color: #f2f2f7 !important;
  border: 0px !important;
  outline: none !important;
  padding: 20px 16px !important;
}

.ant-dropdown-trigger span {
  font-weight: 400 !important;
}

.ant-dropdown-trigger:hover {
  color: #1c1c1e;
}

.ant-picker {
  padding: 10px 16px;
}

.ant-select {
  padding: 6px 10px;
}

.ant-select-selector {
  background: transparent !important;
  border: 0 !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.ant-select-dropdown {
  padding-top: 1rem !important;
  z-index: 97 !important;
}
.ant-picker-dropdown {
  z-index: 97 !important;
}

.ant-btn {
  text-shadow: none;
  border: 0px;
  box-shadow: none;
  padding: 18px 24px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ant-btn span {
  font-style: normal;
  font-weight: bold;
  line-height: 22px;
}

.ant-btn-primary:hover {
  background: #eb964b !important;
  border: 0 !important;
}

.ant-btn-primary:focus {
  background: #ffa351 !important;
}

.ant-btn-primary:active {
  box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1) !important;
}

.ant-checkbox:hover,
.ant-checkbox-checked:hover {
  border: 0px !important;
}

.ant-checkbox .ant-checkbox-inner {
  background: #f2f2f7 !important;
  border: 1px solid #e5e5ea !important;
  box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1) !important;
  border-radius: 4px !important;
  width: 24px !important;
  height: 24px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #1c1c1e !important;
  border: 0px !important;
  box-shadow: inset 0px 2px 2px -1px rgba(28, 28, 30, 0.1) !important;
  border-radius: 4px !important;
  width: 24px !important;
  height: 24px !important;
}

.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  opacity: 0.8;
}

.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: " ";
}

.ant-checkbox-checked::after {
  border: 0px !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  width: 6.71428571px;
  height: 10.14285714px;
  left: 30%;
}

.padding-10px {
  padding: 10px !important;
}

.mr-10px {
  margin-right: 10px !important;
}

.ant-input,
.ant-input-number,
.ant-input-affix-wrapper {
  padding: 8px;
  background: #f2f2f7;
  border-radius: 4px;
  margin: 10px 0px;
  color: #8e8e93;
  border: 1px solid transparent;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  background-color: #ffffff;
  border: 1px solid #ffa351;
}

.ant-input-affix-wrapper:focus .ant-input,
.ant-input-affix-wrapper-focused .ant-input {
  background-color: #ffffff;
}

.ant-btn::placeholder {
  color: #8e8e93;
}

.ant-dropdown-trigger:hover span,
.ant-dropdown-trigger:focus span,
.ant-dropdown-trigger:visited span {
  color: #1c1c1e;
}

.ant-select-selection-search-input,
.ant-select-selection-search-input > * {
  cursor: pointer !important;
}

.ant-select-item-option-selected {
  background-color: #fff !important;
  color: #1c1c1e !important;
}
.ant-collapse
  > .ant-collapse-item.ant-collapse-no-arrow
  > .ant-collapse-header {
  padding-left: 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}

.ant-black-text-button {
  color: #1c1c1e !important;
}

.ant-slider-rail {
  background-color: #e5e5ea !important;
  height: 8px !important;
}

.ant-slider-track {
  background-color: #ffa351 !important;
  height: 8px !important;
}

.ant-slider-handle {
  background-color: #ffa351 !important;
  width: 20px !important;
  height: 20px !important;
  border: 0px !important;
}

.ant-drawer {
  z-index: 100;
}

.ant-drawer-body {
  padding: 0;
}

@primary-color: #FFA351;@link-color: #FFA351;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color: #1C1C1E;@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 4px;@border-color-base: #e5e5ea;